/* eslint react/display-name: 0 */
import React from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import { animated, Spring, config, Trail } from "react-spring";
import styled from "styled-components";
import { Layout, ProjectItem } from "../components";

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  width: 100%;
  padding: 0 1rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-width: 64rem;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  padding: 2rem 1rem;
  max-width: 55rem;
  margin: 0 auto;
  margin-bottom: 1rem;

  h3 {
    margin-top: 0;
    font-size: 2rem;
    @media (max-width: ${(props) => props.theme.breakpoints.s}) {
      font-size: 1.5rem;
    }
  }

  p {
    max-width: 32em;
    margin-bottom: 0;
  }
`;

const Lead = styled.p`
  max-width: 100% !important;
  font-size: 18px;
`;

const Index = ({
  data: {
    allMdx: { edges: projectEdges },
  },
}) => (
  <Layout>
    <Spring
      native
      config={config.slow}
      delay={500}
      from={{ opacity: 0, transform: "translate3d(0, -30px, 0)" }}
      to={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
    >
      {(props) => (
        <animated.div style={props}>
          <TextWrapper>
            <h3>Büro für Grafikdesign und kreative Markenideen</h3>
            <Lead>
              Kreative Ideen für Marken und Unternehmen, herausragende
              Gestaltung und Texte, die man gerne liest. All das sind wichtige
              Bausteine für Ihren Erfolg, denn als Unternehmen und Dienstleister
              brauchen Sie ein deutliches Profil, das auffällt, und Botschaften,
              die merkfähig, sympathisch und relevant kommuniziert werden.{" "}
              <Link to='/about'>Weiterlesen ...</Link>
            </Lead>
          </TextWrapper>
        </animated.div>
      )}
    </Spring>
    <ListWrapper>
      <Trail
        delay={1000}
        native
        items={projectEdges}
        keys={(project) => project.node.fields.slug}
        from={{ opacity: 0, transform: "translate3d(0, -30px, 0)" }}
        to={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
      >
        {(project, index) => (props) => (
          <ProjectItem
            testid={`projectItem-${index}`}
            style={props}
            key={project.node.fields.slug}
            node={project.node}
          />
        )}
      </Trail>
    </ListWrapper>
  </Layout>
);

export default Index;

Index.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query IndexQuery {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { sourceInstanceName: { eq: "projects" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            service
            color
            client
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 850
                  quality: 90
                  traceSVG: { color: "#f3f3f3" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
